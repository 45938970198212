<template>
  <div class="item-report-summary invoice-report-items">
    <div
      class="summary-toolbar"
      v-if="title"
    >
      <h2>{{title}}</h2>
    </div>

    <table>
      <thead>
        <tr>
          <th></th>
          <th>Transactions</th>
          <th>Sales</th>
          <th>Sales EUR</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in reportItems"
          :key="item.key"
          :class="[item.key]"
        >
          <td>{{item.name}}</td>
          <td>{{item.report.transactions}}</td>
          <td>{{formatCurrency(item.report.amount, item.currency, true)}}</td>
          <td>{{formatCurrency(item.report.eur, 'eur', true)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue';
import { currencyMethodsMixin } from '@/mixin/currency';

export default {
  props: {
    report: Object,
    title: {
      type: String,
      default: '',
    },
  },
  mixins: [currencyMethodsMixin],
  setup(props) {
    const { report } = toRefs(props);
    const reportItems = computed(() => {
      const items = [
        {
          key: 'eur', name: 'EUR', currency: 'EUR', report: report.value.totalByCurrency.eur,
        },
        {
          key: 'usd', name: 'USD', currency: 'USD', report: report.value.totalByCurrency.usd,
        },
        {
          key: 'inr', name: 'INR', currency: 'INR', report: report.value.totalByCurrency.inr,
        },
        {
          key: 'total', name: 'Total', currency: 'EUR', report: report.value.total,
        },
      ];
      return items;
    });

    return {
      reportItems,
    };
  },
};
</script>

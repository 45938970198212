<template>
  <div class="screen-admin-page screen-admin-usaga-report">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>Transaction Report {{currentDay.format('MMM YYYY')}}</h1>
          <div class="search-overlay" v-if="data.openSearchTab">
            <div class="wrapper">
              <div class="header">
                <input type="text" v-model="data.inputVal" placeholder="Search">
                <button class="close-btn" @click="close()">X</button>
              </div>
              <div v-if="data.suggestionList" class="suggestion-list"
                :class="data.suggestionList && data.suggestionList.docs &&
                data.suggestionList.docs.length > 0 ? 'add-height': ''"
              >
                <div>
                  <a
                    :href="consoleLink+'admin/transaction-list/'+item.transaction_id+'/preview?search='+data.inputVal"
                    v-for="item in data.suggestionList.docs" :key="item.key" class="list-wrapper">
                    <div class="align-left">
                      <span>{{item.owner_name}}</span>
                    </div>
                    <div class="align-right">
                      <span>{{item.project_key}}</span>
                      <span>{{item.transaction_id}}</span>
                      <span>{{item.owner_email}}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tools">
          <span class="search-icon"  @click="data.openSearchTab = true">
            Search
            <svg>
              <use v-bind="{'xlink:href':'#search-icon'}" />
            </svg>
          </span>
          <button
            @click="updateMonth(prevMonth)"
            v-if="prevMonth"
            class="btn"
          >{{prevMonth.format('MMM YYYY')}}</button>
          <button
            class="btn"
            disabled
          >{{currentDay.format('MMM YYYY')}}</button>
          <button
            @click="updateMonth(nextMonth)"
            v-if="nextMonth"
            class="btn"
          >{{nextMonth.format('MMM YYYY')}}</button>
        </div>
      </div>
      <rz-request-state :state="state" />
      <div
        class="usage-report admin-screen-content"
        v-if="state.status.isLoaded"
      >
        <div class="item-list-row item-list">
          <TransactionReportSummary
            class="item-list-col"
            :title="`Unpaid Transactions ${currentDay.format('MMM YYYY')}`"
            :report="report.unpaid" />
          <TransactionReportSummary
            class="item-list-col"
            :title="`Paid Transactions ${currentDay.format('MMM YYYY')}`"
            :report="report.paid" />
        </div>
        <hr />
        <FilterableView
          :filterables="filterables"
          @item-toggle="onFilterItemToggle"
        >
          <div class="item-list-row item-list">
            <div class="item-list-col item-report-summary transaction">
              <div
                class="summary-toolbar"
              >
                <h2>Transactions</h2>
              </div>
              <table class="no-row-border no-title">
                <thead>
                  <tr>
                    <th>Transaction Date</th>
                    <th>Project Name</th>
                    <th>Amount</th>
                    <th>Balance Amount</th>
                    <th>Transaction For</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in report.items"
                    :key="item.key"
                  >
                    <td class="add-pointer" @click="showPreviewTransaction(item , false)">
                      {{$filters.utcLocalDate(item.transaction_date)}}
                    </td>
                    <td class="add-pointer" @click="showPreviewTransaction(item , true)">
                      {{item.project.name}}
                    </td>
                    <td :class="[item.kind !== 'money' ? 'virtual-money': '']">
                      {{formatCurrency(item.amount, item.currency)}}
                      <span v-if="item.kind !== 'money'">
                        <svg>
                          <use v-bind="{ 'xlink:href': '#virtual-money-icon' }" />
                        </svg>
                      </span>
                    </td>
                    <td>
                      {{item.balance_amount && item.balance_amount!== null ?
                      formatCurrency(item.balance_amount, item.currency) : '-'}}
                    </td>
                    <td class="capitalise">
                      {{item.transaction_for ? (item.transaction_for.split('_').join(' '))
                       : '-'}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </FilterableView>
        <QuickPreviewTransaction />
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import {
  ref, onMounted, reactive, watch,
} from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  ProjectList,
  FilterableItem,
  Filterables,
  TransactionReport,
} from '@/store/modules/admin';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import FilterableView from '@/components/admin/Filterable.vue';
import { currencyMethodsMixin } from '@/mixin/currency';
import TransactionReportSummary from '@/components/admin/TransactionReportSummary.vue';
import QuickPreviewTransaction from '@/components/admin/QuickPreviewTransaction.vue';
import Config from '@/config';

export default {
  mixins: [currencyMethodsMixin],
  components: {
    FilterableView,
    QuickPreviewTransaction,
    TransactionReportSummary,
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
    };
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = ref(new RequestState());
    const report = ref([]);
    const currentDay = ref(moment());
    const prevMonth = ref(null);
    const nextMonth = ref(null);
    const actualItems = ref([]);
    const unpaidTransactions = ref([]);
    const paidTransactions = ref([]);
    const previewTransaction = ref({});
    let month = ref({}) || route.query.month;
    let year = ref({}) || route.query.year;
    const showPaidItem = ref(
      new FilterableItem({
        name: 'Show Paid Items',
        number: 0,
        isCheckbox: true,
        selected: true,
        item: [],
      }),
    );
    const showUnpaidItem = ref(
      new FilterableItem({
        name: 'Show Unpaid Items',
        number: 0,
        isCheckbox: true,
        selected: false,
        item: [],
      }),
    );
    const showVirtualMoney = ref(
      new FilterableItem({
        name: 'Show Virtual Money Items',
        number: 0,
        isCheckbox: true,
        selected: false,
        item: [],
      }),
    );
    const filterables = ref(new Filterables({
      items: [
        showPaidItem.value,
        showUnpaidItem.value,
        showVirtualMoney.value,
      ],
    }));

    const showPreviewTransaction = (transaction, val) => {
      let showProjectInfo;
      route.query = showProjectInfo;
      route.query.showProjectInfo = val;
      previewTransaction.value = transaction;
      if (route && route.query && route.query.group) {
        // eslint-disable-next-line max-len
        router.push({
          name: 'adminQuickTransactionPreview',
          params: {
            type: route.query.group,
            key: transaction.key,
          },
          query: {
            year,
            month,
          },
        });
      } else {
        router.push({
          name: 'adminQuickTransactionPreview',
          params: {
            type: 'paidTransactions',
            key: transaction.key,
          },
          query: {
            year,
            month,
          },
        });
      }
    };

    const data = reactive({
      openSearchTab: false,
      inputVal: null,
      suggestionList: {},
    });

    const close = () => {
      data.suggestionList = null;
      data.inputVal = '';
      data.openSearchTab = false;
    };

    watch(() => data.inputVal, () => ProjectList.searchQuery(data.inputVal, 'transaction').then((list) => {
      data.suggestionList = list.data.data;
    }));

    const showPreviewTransactionUsingKey = (key) => {
      TransactionReport.fetchTransaction(key).then((response) => {
        showPreviewTransaction(response);
      });
    };

    const hidePreviewTransaction = () => {
      previewTransaction.value = {};
      router.push({
        name: 'transactionList',
      });
      store.commit('admin/updatePreviewTransaction', { transaction: null });
      return false;
    };

    const updateFilterItems = () => {
      const allItems = actualItems.value.items;
      report.value.items = [];
      let filteredItems = [];
      const paid = paidTransactions.value;
      const unpaid = unpaidTransactions.value;
      report.value.unpaidItems = [];
      report.value.paidItems = [];

      showUnpaidItem.value.number = unpaid.items.filter((x) => (x.payment_status.isPaymentFailed
       || x.payment_status.isWaitingForPayment) && x.kind === 'money').length;
      showPaidItem.value.number = paid.items.filter((x) => (x.payment_status.isPaymentSuccess
       || x.payment_status.isPaymentNotRequired) && x.kind === 'money').length;
      showVirtualMoney.value.number = allItems.filter((x) => x.kind
      !== 'money').length;

      if (showUnpaidItem.value.selected && !showPaidItem.value.selected
      && !showVirtualMoney.value.selected) {
        filteredItems = unpaid.items.filter((x) => (x.payment_status.isPaymentFailed
        || x.payment_status.isWaitingForPayment) && x.kind === 'money');
        route.query.group = {};
        route.query.group = 'unpaidTransactions';
      } else if (showPaidItem.value.selected && !showUnpaidItem.value.selected
      && !showVirtualMoney.value.selected) {
        filteredItems = paid.items.filter((x) => (x.payment_status.isPaymentSuccess
        || x.payment_status.isPaymentNotRequired) && x.kind === 'money');
        route.query.group = {};
        route.query.group = 'paidTransactions';
      } else if (!showUnpaidItem.value.selected && !showPaidItem.value.selected
      && !showVirtualMoney.value.selected) {
        filteredItems = null;
        route.query.group = {};
        route.query.group = 'null';
      } else if (showVirtualMoney.value.selected && !showUnpaidItem.value.selected
      && !showPaidItem.value.selected) {
        filteredItems = allItems.filter((x) => x.kind !== 'money');
        route.query.group = {};
        route.query.group = 'virtualMoney';
      } else if (showUnpaidItem.value.selected && !showPaidItem.value.selected
      && showVirtualMoney.value.selected) {
        filteredItems = allItems.filter((x) => x.payment_status.isPaymentFailed
        || x.payment_status.isWaitingForPayment);
        route.query.group = {};
        route.query.group = 'unpaidWithVirtual';
      } else if (!showUnpaidItem.value.selected && showPaidItem.value.selected
      && showVirtualMoney.value.selected) {
        filteredItems = allItems.filter((x) => x.payment_status.isPaymentSuccess
        || x.payment_status.isPaymentNotRequired);
        route.query.group = {};
        route.query.group = 'paidWithVirtual';
      } else if (showUnpaidItem.value.selected && showPaidItem.value.selected
      && !showVirtualMoney.value.selected) {
        filteredItems = allItems.filter((x) => (x.payment_status.isPaymentSuccess
        || x.payment_status.isPaymentNotRequired || x.payment_status.isPaymentFailed
        || x.payment_status.isWaitingForPayment)
        && x.kind === 'money');
        route.query.group = {};
        route.query.group = 'withoutVirtual';
      } else {
        filteredItems = allItems;
        route.query.group = {};
        route.query.group = 'all';
      }
      report.value.items = filteredItems;
    };

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      year = currentDay.value.year();
      month = currentDay.value.format('MM');
      TransactionReport.fetch(year, month).then((_report) => {
        report.value = _report;
        actualItems.value = report.value.items;
        unpaidTransactions.value = report.value.unpaid;
        paidTransactions.value = report.value.paid;
        state.value.status = RequestStateStatus.Loaded;
        updateFilterItems();
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const updateMonths = () => {
      prevMonth.value = currentDay.value.clone().add(-1, 'months');
      if (moment().isSame(currentDay.value, 'month')) {
        nextMonth.value = null;
      } else {
        nextMonth.value = currentDay.value.clone().add(1, 'months');
      }
    };

    const updateMonth = (monthVal) => {
      currentDay.value = monthVal;
      updateMonths();
      fetchData();
    };

    const onFilterItemToggle = () => {
      updateFilterItems();
    };

    const onTransactionClick = (transaction) => {
      showPreviewTransactionUsingKey(transaction.key);
    };

    onMounted(() => {
      updateMonths();
      fetchData();
    });

    return {
      state,
      report,
      updateMonth,
      currentDay,
      prevMonth,
      nextMonth,
      filterables,
      onFilterItemToggle,
      previewTransaction,
      onTransactionClick,
      showPreviewTransactionUsingKey,
      showPreviewTransaction,
      hidePreviewTransaction,
      data,
      close,
      year,
      month,
    };
  },
};
</script>
